import React from "react";
import ColoredDropDownSection from "../../../Common/CommonSections/ColoredDropDownSection/DropDownSection";

const dropDownOption = [
    {
        title: "In-house team",
        description: `
            In-house team is a team that works within the company's physical office space and has
            direct access to the company's resources and personnel.
        `,
    },
    {
        title: "Offshore team",
        description: `
            Offshore team is a team that is located in a different country than the company's headquarters. 
            They can be less expensive than in-house teams, but they may have different work hours
            and cultural differences to contend with.
        `,
    },
    {
        title: "Nearshore team",
        description: `
            Nearshore dedicated software development teams are teams that is located in a neighboring country or region.
            They offer many of the same benefits as an offshore team, but with fewer cultural and time zone differences.
        `,
    },
    {
        title: "Managed team",
        description: `
            Managed team is a team that is managed by an external service provider or agency. 
            The service provider manages the team and provides them with the necessary
            resources and infrastructure.
        `,
    },
    {
        title: "Extended team",
        description: `
            Extended team is a team that includes both in-house and external team members. 
            This approach provides the company with additional resources and expertise without
            the need to hire new employees.
        `,
    },
];

const TeamTypesSection = () => (
    <ColoredDropDownSection
        title="Types Of Dedicated Development Team"
        description={
            <>
                There are different types of dedicated development teams, each
                with its own unique advantages and disadvantages.
                <br />
                Here are some of the most common types so you can decide which
                one fits your needs better:
            </>
        }
        dropDownOptions={dropDownOption}
    />
);

export default TeamTypesSection;
