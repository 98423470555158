import React from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import styled from "styled-components";
import useComponentsGenerator from "@hooks/useComponentsGenerator";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "@constants";
import DropDownItem from "./DropDownItem/DropDownItem";

const ColoredDropDownSection = ({ title, description, dropDownOptions }) => {
    const [componentElements, generateComponents] =
        useComponentsGenerator(dropDownOptions);

    return (
        <DropDownSectionContainer>
            <PageHeading heading={title} mainParagraph={description} />
            <DropDownItemsContainer>
                {generateComponents((dropDownOption, index) => {
                    componentElements.push(
                        <DropDownItem
                            key={index}
                            dropDownOption={dropDownOption}
                        />
                    );
                })}
            </DropDownItemsContainer>
        </DropDownSectionContainer>
    );
};

const DropDownSectionContainer = styled.div`
    margin: 5rem 0;
    cursor: pointer;

    & .page-heading {
        width: 65%;
        padding: 0 10.1875rem;
        margin-bottom: 2.5rem;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            width: auto;
            margin-bottom: 1.5rem;
            padding: 0 6.25rem;
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            padding: 0 1.5rem;
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 2.5rem 0;
    }
`;

const DropDownItemsContainer = styled.div`
    & > div:first-of-type {
        border-top: 0.0625rem solid #3a1a3c;
    }
`;

export default ColoredDropDownSection;
